import React from "react";
import SlideRoute from "@routes/slide/Slide";
import { Router } from "@reach/router";

export const Slide = () => {
    return (
      <Router>
        <SlideRoute path="/slide/:id" />
      </Router>
    );
  };

export default Slide;