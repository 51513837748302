import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import api from "@api";
import { useRecoilValue } from "recoil";
import { authState } from "@state/auth";
import { Helmet } from "react-helmet"
import { Body2Bold } from "@metyis-ds/typography";
import { Buffer } from "buffer";

export const Slide: React.FC<RouteComponentProps> = ({
    location
}) => {
    const [imageSrc, setImageSrc] = useState<any>();
    const [slideName, setSlideName] = useState<string>();
    const auth = useRecoilValue(authState);
    const [_, grans] = location!.pathname.split("/slide/");

    const code = Buffer.from(grans, "base64").toString();


    const getSlide = async ( slideIndex: number, slideName: string ) => { 
        // const granularities = decodeURI(grans).split("_");
        // const { data }= await api.postSlideDiscovery({
        //     granularities
        // });
        // console.log(data);
        if(slideIndex != -1) {
            setSlideName(slideName)
            api.postSlide({
                gameid: auth.gameid,
                userid: auth.userid,
                index: slideIndex
            })
            .then((b) => b.blob())
            .then(async (blob:any) => {
                setImageSrc(URL.createObjectURL(blob));
            })
            .catch(err => {
                console.log("ERR")
            })
        }
    }

    useEffect(() => {
        if(location && grans && code) {
            const slideIndex = code.split("_")[0]
            const slideName = code.split("_")[1]
            getSlide(+slideIndex, slideName);
        }
    }, [location])


    return <div className="flex flex-col items-center p-[5%] w-full h-screen">
        {slideName && <Helmet>
            <meta charSet="utf-8" />
            <title>{slideName}</title>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        </Helmet>}
        <div className="px-8 pt-4">
            <Body2Bold>{slideName}</Body2Bold>
        </div>
        {imageSrc && <img className="h-screen" src={imageSrc} />}
    </div>
}
export default Slide;